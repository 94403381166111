<template>
  <section>
    <b-loading :active="loadingProperties" :is-full-page="false"></b-loading>

    <b-modal
      :active.sync="showFilterModal"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
    >
      <template #default>
        <TableColumnFilter
          :columns="visibleFiltersModalColumn"
          :data="filters"
          :draggable="false"
          @toggleVisibility="onFiltersToggleVisibility"
          @toggleVisibilityAll="onFiltersToggleVisibility"
        >
          <template #header>
            <p class="modal-card-title is-size-5">
              {{
                isMobile
                  ? $t("table.col-options.mobile.header")
                  : $t("table.col-options.header")
              }}
            </p>
          </template>
          <template #beforeTable>
            <p class="is-size-5">
              {{
                isMobile
                  ? $t("table.col-options.mobile.col-filter-title")
                  : $t("table.col-options.col-filter-title")
              }}
            </p>
          </template>
        </TableColumnFilter>
      </template>
    </b-modal>

    <!-- property filters -->
    <section class="section has-background-eurotext-light">
      <div class="container">
        <button class="button is-primary block" @click="showFilterModal = true">
          {{ $t("buttons.hide_search_filters") }}
        </button>

        <form
          @submit.prevent="fetchDocuments"
          @reset.prevent="resetForm"
          class="search-filters block"
        >
          <div class="columns is-multiline">
            <div
              class="column is-one-third-tablet is-one-third-desktop is-one-quarter-widescreen is-one-fifth-fullhd custom-column-filter-align"
            >
              <b-field>
                <template #label
                  ><span class="has-text-light">{{
                    $t(`concatOperator`)
                  }}</span></template
                >
                <b-select
                  placeholder="Select an operator"
                  v-model="selectedConcatOperator"
                  expanded
                >
                  <option v-for="op in concatOperators" :value="op" :key="op">
                    {{ op }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div
              v-for="(filter, index) in visibleFilters"
              :key="`${filter.name}_${index}`"
              class="column is-one-third-tablet is-one-third-desktop is-one-quarter-widescreen is-one-fifth-fullhd custom-column-filter-align search-filter"
            >
              <b-field>
                <template #label
                  ><span class="has-text-light">{{
                    $t(`docclass-properties.${filter.description}`)
                  }}</span></template
                >

                <b-select
                  :value="filter.operator"
                  @input="changeFilterOperator($event, filter)"
                >
                  <option
                    v-for="option in getFilterOperator(filter)"
                    :value="option.key"
                    :key="option.key"
                    v-html="option.icon"
                  ></option>
                </b-select>

                <!-- If filter is class id, use a select with all class values -->
                <!-- <b-select
                  v-if="filter.name === 'class_id'"
                  v-model="filter.value"
                  expanded
                >
                  <option :value="null"></option>
                  <option
                    v-for="clazz in classList"
                    :value="clazz.id"
                    :key="clazz.id"
                  >
                    {{ clazz.name }}
                  </option>
                </b-select> -->

                <b-dropdown
                  v-if="filter.name === 'class_id'"
                  v-model="filter.value"
                  multiple
                  scrollable
                  max-height="200"
                  aria-role="list"
                  expanded
                  class="et-scrollbar"
                >
                  <template #trigger="{ active }">
                    <button
                      type="button"
                      class="button arrow-right is-fullwidth"
                    >
                      <span>{{
                        $t("multiselect_label", {
                          number: filter.value ? filter.value.length : "0",
                        })
                      }}</span>
                      <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                    </button>
                  </template>

                  <b-dropdown-item
                    aria-role="listitem"
                    v-for="clazz in classList"
                    :value="clazz.id"
                    :key="clazz.id"
                  >
                    <span>{{ clazz.name }}</span>
                  </b-dropdown-item>
                </b-dropdown>

                <!-- If filter is stato conservazione, use a select with all status values -->

                <b-dropdown
                  v-else-if="filter.name === 'stato_conservazione'"
                  v-model="filter.value"
                  multiple
                  aria-role="list"
                  expanded
                >
                  <template #trigger="{ active }">
                    <button
                      type="button"
                      class="button arrow-right is-fullwidth"
                    >
                      <span>{{
                        $t("document.status.select_label", {
                          number: filter.value ? filter.value.length : "0",
                        })
                      }}</span>
                      <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                    </button>
                  </template>

                  <b-dropdown-item
                    aria-role="listitem"
                    v-for="status in docStatusList"
                    :value="status"
                    :key="status"
                  >
                    <span>{{ $t(`document.status.${status}`) }}</span>
                  </b-dropdown-item>
                </b-dropdown>

                <custom-b-date-picker
                  v-else-if="isDate(filter.type) || isDateTime(filter.type)"
                  icon="calendar-today"
                  :range="filter.operator === operators.BETWEEN.key"
                  expanded
                  v-model="filter.value"
                  :locale="$i18n.locale"
                  :dateFormat="$store.getters.getDatePattern"
                >
                </custom-b-date-picker>

                <boolean-input
                  v-else-if="isBoolean(filter.type)"
                  expanded
                  v-model="filter.value"
                  style="width: 100%"
                >
                </boolean-input>

                <b-taginput
                  v-else-if="filter.operator === operators.IN.key"
                  v-model="filter.value"
                  :confirm-keys="[';', 'Enter', 'Tab']"
                  :on-paste-separators="[';']"
                  :maxtags="$config.search.max_search_tags"
                  has-counter
                  expanded
                  check-infinite-scroll
                  ellipsis
                  aria-close-label="Delete this tag"
                  class="has-text-light"
                ></b-taginput>

                <b-input v-else v-model="filter.value" expanded></b-input>
              </b-field>
            </div>
          </div>

          <div class="buttons">
            <b-button
              type="is-primary"
              native-type="submit"
              icon-left="magnify"
            >
              {{ $t("buttons.search") }}
            </b-button>
            <b-button type="is-primary" native-type="reset" icon-left="eraser">
              {{ $t("buttons.erase") }}
            </b-button>
          </div>
        </form>
      </div>
    </section>

    <section class="section" v-if="tableColumns.length">
      <document-table
        :data="tableData"
        :tableColumns="tableColumns"
        :isLoadingRows="loadingDocuments"
        :total="totalTablePages"
        :perPage="tablePerPage"
        :totalRecords="totalDocumentNum"
        :downloadTypes="downloadTypes"
        :saveState="true"
        :stateKey="stateKey"
        :defaultOrderedColumnsNames="[]"
        :showEmitStateCheckbox="true"
        :backend-sorting="true"
        @page="onTablePage($event)"
        @row-click="openDetail"
        @download="download"
        @download-total="downloadTotal"
        @column-state-close="onTableColumnsStateClose"
        @sort-data="onTableSort"
      >
        <template #cell="{ cellData, column }">
          <span
            v-if="cellData && column.field === 'stato_conservazione'"
            class=""
            >{{ $t(`document.status.${cellData}`) }}</span
          >
          <template v-else>{{ cellData }}</template>
        </template>
      </document-table>
    </section>
  </section>
</template>

<script>
import { commonMixin, searchPageMixin, uaMixin } from "@/mixins";
import { searchService } from "@/services";
import {
  Operators,
  KValueTypes,
  KAllDocStatus,
  ConcatOperators,
  checkType,
} from "@/helpers/constants";
import {
  KDocumentDownloadType,
  getDocumentDownloadType,
} from "@/helpers/constants";
import CustomBDatePicker from "@/components/inputs/CustomBDatePicker.vue";
import BooleanInput from "@/components/inputs/BooleanInput.vue";
import DocumentTable from "@/components/DocumentTable/DocumentTable";
import TableColumnFilter from "@/components/DocumentTable/TableColumnFilter";
import * as dateUtils from "@/helpers/date";
import { mapGetters } from "vuex";
import { downloadService } from "@/services";
import { KFilterSpecificOperators } from "../helpers/constants";

const DEFAULT_OPERATOR = "E";
const ONLY_COMMON_PROPERTIES = false;

export default {
  components: {
    CustomBDatePicker,
    BooleanInput,
    DocumentTable,
    TableColumnFilter,
  },
  mixins: [commonMixin, searchPageMixin, uaMixin],
  data() {
    return {
      loadingProperties: false,
      loadingDocuments: false,
      filters: [],
      documentData: [],
      valueTypes: KValueTypes,
      operators: Operators,
      concatOperators: [...ConcatOperators],
      selectedConcatOperator: ConcatOperators[0],
      docStatusList: [...KAllDocStatus],
      downloadTypes: getDocumentDownloadType(),
      filterClassListInput: "",
      // visibleFiltersModalColumn: [],
    };
  },
  computed: {
    ...mapGetters("menu", ["getCompanyClasses", "getClassIdByName"]),
    classList() {
      return this.getCompanyClasses(this.companyName);
    },
    classListFiltered() {
      return this.classList.filter(
        (item) =>
          item.name
            .toLowerCase()
            .indexOf(this.filterClassListInput.toLowerCase()) >= 0
      );
    },
    isLoading: function () {
      return this.loadingProperties || this.loadingDocuments;
    },
    stateKey() {
      const view = this.$route.path.split("/").pop();
      return `${this.companyName}.${view}`;
    },
    tableColumns() {
      return this.filters.map((f) => {
        return {
          field: f.name,
          label: this.$t(`docclass-properties.${f.description}`),
        };
      });
    },
    tableData() {
      // return this.documentData.map((doc) => {
      //   return doc["properties"];
      // });
      return Array.from(this.documentData, (doc) => doc["properties"]);
    },
  },
  methods: {
    download(payload) {
      const artifacts = payload.artifacts;
      const documents = payload.selected;
      const ids = [];
      for (const doc of documents) {
        ids.push(doc.id);
      }
      let metadataList = [];
      if (
        (artifacts & KDocumentDownloadType.METADATA) ===
        KDocumentDownloadType.METADATA
      ) {
        metadataList = payload.metadata;
      }
      // const isHistory = this.isDocumentHistory(document)
      this.isDownloading = true;
      downloadService
        .downloadDocuments(
          this.companyName,
          this.$i18n.locale,
          "ddMMyyyy",
          true,
          ids,
          artifacts,
          metadataList
        )
        .then((data) => {
          console.log(data);
        })
        .finally(() => (this.isDownloading = false));
    },
    downloadTotal(payload) {
      const artifacts = payload.artifacts;
      this.isDownloading = true;
      const docFilters = this.filters.filter((f) => {
        if (f.value) {
          if (Array.isArray(f.value)) {
            if (f.value.length > 0) {
              // Array not empty
              return true;
            } else {
              // Array empty
              return false;
            }
          }
          return true;
        } else {
          return false;
        }
      });

      const queryFilterGroup = {
        operator: this.selectedConcatOperator,
        filters: docFilters,
      };

      let metadataList = [];
      if (
        (artifacts & KDocumentDownloadType.METADATA) ===
        KDocumentDownloadType.METADATA
      ) {
        metadataList = payload.metadata;
      }
      downloadService
        .downloadAllDocuments(
          this.companyName,
          this.className,
          queryFilterGroup,
          this.$i18n.locale,
          "ddMMyyyy",
          true,
          artifacts,
          metadataList,
          true,
          true
        )
        .then((data) => {
          console.log(data);
        })
        .finally(() => (this.isDownloading = false));
    },
    /**
     * Get documents from web service
     */
    fetchDocuments() {
      // const docFilters = this.filters
      //   .map((f) => {
      //     const ret = { ...f };
      //     let found = this.tableSortingPriority.find((o) => o.field === f.name);
      //     if (found) {
      //       ret.sortOrder = found.order;
      //     }

      //     if (ret.value) {
      //       if (
      //         (Array.isArray(ret.value) && !ret.value.length) ||
      //         (typeof ret.value === "object" && !Object.keys(ret.value).length)
      //       ) {
      //         ret.value = null;
      //       }
      //     }
      //     return ret;
      //   })
      //   .filter((f) => f.value || f.value === false || f.sortOrder);

      const docFilters = this.filters.filter((f) => {
        if (f.value || f.value === false) {
          if (Array.isArray(f.value)) {
            if (f.value.length > 0) {
              // Array not empty
              return true;
            } else {
              // Array empty
              return false;
            }
          }
          return true;
        } else {
          return false;
        }
      });

      const queryFilterGroup = {
        operator: this.selectedConcatOperator,
        filters: docFilters,
      };

      this.loadingDocuments = true;

      const tableSorting = {};
      this.tableSortingPriority.forEach((el) => {
        tableSorting[el.field] = el.order;
      });

      const { companyName, tablePage, tablePerPage } = this;
      const workingDocs = true;
      const historyDocs = true;

      searchService
        .searchDocuments(
          companyName,
          queryFilterGroup,
          tablePage,
          tablePerPage,
          workingDocs,
          historyDocs,
          tableSorting,
          ONLY_COMMON_PROPERTIES
        )
        .then((res) => {
          this.documentData = res["documents"];
          this.tablePage = res["page"];
          this.totalTablePages = res["total_pages"];
          this.totalDocumentNum = res["total_results"];
        })
        .finally(() => (this.loadingDocuments = false));
    },
    /**
     * Get filters from web service
     */
    async fetchFilters() {
      this.loadingProperties = true;
      try {
        const filters = await searchService.fetchSearchFilters(
          this.companyName
        );

        const newFilters = filters.map((f) => {
          let defaultOperator = KFilterSpecificOperators[f.name]
            ? KFilterSpecificOperators[f.name].defaultOperator.key
            : DEFAULT_OPERATOR;
          let o = {
            value: null,
            operator: defaultOperator,
            visible: true,
            label: this.$t(`docclass-properties.${f.description}`),
            ...f,
          };
          if (o.name === "this_year" && !!this.$config.search.auto_year_filter)
            o.value = new Date().getUTCFullYear();
          return o;
        });
        this.filters = newFilters;
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingProperties = false;
      }
    },
    isDocHistory(docId) {
      const found = this.documentData.find((data) => {
        return docId === data.properties.id;
      });
      if (found) {
        return found.history;
      }
      return undefined;
    },
    parseDate(date) {
      return dateUtils.parseDate(date, "");
    },
    openDetail(payload) {
      const docId = payload.id;
      const classId = this.getClassIdByName(
        payload["class_id"],
        this.companyName
      );
      const hisotry = this.isDocHistory(docId);
      const tab = {
        id: docId,
        component: "DocumentDetail",
        label: `Doc ${docId}`,
        icon: this.$config.icons.tabs.document_detail,
        props: {
          classId,
          companySchema: this.companyName,
          documentId: docId,
          properties: [],
          isHistory: hisotry,
          stateKey: this.stateKey,
        },
      };
      this.$store.dispatch("tabs/openTab", tab);
    },
    saveOrderState() {
      if (this.saveState) {
        localStorage.setItem(
          this.mStateKey,
          JSON.stringify(this.visibleFiltersNames)
        );
      }
    },
    ...checkType,
  },
  // beforeMount() {
  //   const stateString = localStorage.getItem(this.stateKey);
  //   if (stateString) {
  //     const restoredState = JSON.parse(stateString);
  //     this.visibleFiltersNames = restoredState.visible;
  //   }
  // },
  created() {
    this.fetchFilters().then(() => {
      this.loadFiltersState();
    });

    // this.visibleFiltersModalColumn = [
    //   {
    //     field: "label",
    //     label: this.$t("table.col-options.column-label"),
    //   },
    // ];
  },
};
</script>

<style lang="scss" scoped></style>
